export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    authRequired: false,
    hideAfterLogin: false,
  },
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'GridIcon',
    authRequired: true,
    hideAfterLogin: false,
  },
  {
    title: 'Create New App',
    route: 'addNewApp',
    icon: 'FilePlusIcon',
    authRequired: true,
    hideAfterLogin: false,
  },
  {
    title: 'Pricing',
    route: 'pricing',
    icon: 'DollarSignIcon',
    authRequired: false,
    hideAfterLogin: false,
  },
  {
    title: 'Purchases',
    route: 'purchases',
    icon: 'CreditCardIcon',
    authRequired: true,
    hideAfterLogin: false,
  },
  {
    title: 'Contact Us',
    route: 'contact',
    icon: 'HelpCircleIcon',
    authRequired: false,
    hideAfterLogin: false,
  },
  {
    title: 'Sign In',
    route: 'login',
    icon: 'UserIcon',
    authRequired: false,
    hideAfterLogin: true,
  },
  {
    title: 'Get 3 Free Builds',
    route: 'register',
    icon: 'GiftIcon',
    authRequired: false,
    hideAfterLogin: true,
  },
]
